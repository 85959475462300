import React from 'react'

export default class TestimonialSection extends React.Component {
  render() {
    
    const { testimonialSection } = this.props;

    return (
      <section className="testimonial-section pt-5 pb-5">
        <div className="container mt-5 mb-5 testimonial-section-container">
          <h1 className="testimony">
            {testimonialSection.testimony.testimony}
          </h1>
          <p className="testimonial-by">
            {testimonialSection.testimonyBy}
          </p>
        </div>
      </section>
    )
  }
}
