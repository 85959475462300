import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import Carousel from 'react-bootstrap/Carousel';
import Footer from '../components/footer';
import Layout from '../components/layout';
import NavSection from '../components/nav-section';
import TestimonialSection from '../components/testimonial-section';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historySection: 'active',
      pressSection: '',
      brandSection: '',
    };
    this.timeoutID = {};
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.sectionScrollHandler);
    }
  }

  sectionScrollHandler = e => {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    let historySectionPosition =
      document.getElementById('historySection') &&
      document.getElementById('historySection').offsetTop;
    let pressSectionPosition =
      document.getElementById('pressSection') &&
      document.getElementById('pressSection').offsetTop;
    let brandSectionPosition =
      document.getElementById('brandSection') &&
      document.getElementById('brandSection').offsetTop;

    if (winScroll >= historySectionPosition) {
      document
        .querySelectorAll('.side-scrollers, .center-btns')
        .forEach(function (el) {
          el.classList.remove('active');
        });
      document.getElementById('history-01') &&
        document.getElementById('history-01').classList.add('active');
      document.getElementById('btn-history') &&
        document.getElementById('btn-history').classList.add('active');
    }
    if (winScroll >= pressSectionPosition - 10) {
      document
        .querySelectorAll('.side-scrollers, .center-btns')
        .forEach(function (el) {
          el.classList.remove('active');
        });
      document.getElementById('press-02') &&
        document.getElementById('press-02').classList.add('active');
      document.getElementById('btn-press') &&
        document.getElementById('btn-press').classList.add('active');
    }
    if (winScroll >= brandSectionPosition - 10) {
      document
        .querySelectorAll('.side-scrollers, .center-btns')
        .forEach(function (el) {
          el.classList.remove('active');
        });
      document.getElementById('brand-03') &&
        document.getElementById('brand-03').classList.add('active');
      document.getElementById('btn-brand') &&
        document.getElementById('btn-brand').classList.add('active');
    }
  };

  scrollHandler = e => {
    this.setState({
      historySection: '',
      pressSection: '',
      brandSection: '',
    });
    this.setState({ [e.target.dataset.id]: 'active' });
    var target = document.getElementById(e.target.dataset.id);
    target.scrollIntoView(true);
  };
  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    const { historySection, pressSection, brandSection } = this.state;
    const {
        section1Image1,
        section1Image1Caption,
        section1Image2,
        section1Image2Caption,
        section1Image2Credit,
        section1ImageCredit,
        section1Paragraph1,
        section1Paragraph2,
        section1Subtitle,
        section1TabName,
        section1Title,
        section2Articles,
        section2SubTitle,
        section2TabName,
        section2Title,
        section3TabName,
        section3Title,
        section3Articles, 
        mobileTabName01,
        mobileTabName02,
        mobileTabName03,
        tabName01,
        tabName02,
        tabName03,
    } = get(this, 'props.data.allContentfulAboutAboutPage.nodes[0]');
    const pressCarousel = get(
      this,
      'props.data.allContentfulAboutPagePressSection.edges'
    );
    const testimonialAbout = get(
      this, 
      'props.data.allContentfulTestimonialSection.edges[0].node'
    );
    
    const footer = get(this, 'props.data.allContentfulFooter.edges')
    
    const section2Group = Math.ceil(pressCarousel.length/3);

    // SECTION 2 CAROUSEL FOR LOOP
    const section2CarouselSlideData = (index) => {
        return (
          <div key={index} className="col-md-4">
            <a href={section2Articles[index].pressArticleLink}>
              <img
                className="w-100"
                src={section2Articles[index].pressFeaturedImage.file.url}
                alt={section2Articles[index].pressArticleTitle}
              />
              <h2 className="font-bigcas mt30 red">
                {section2Articles[index].pressArticleTitle}
              </h2>
              <p className="font-gtaM mt20 black">{section2Articles[index].pressArticleSource}</p>
            </a>
          </div>
        );
    };

    const section2CarouselSlide = (index) => {
        const section2Slides = [];
        const j = index * 3;
        for (let i = j; i < j + 3 && i < pressCarousel.length; i++) {
            section2Slides.push(
            section2CarouselSlideData(i)
          );
        };

        return(
          <Carousel.Item>
            <div className="container sm-w85">
              <div className="row">
                {section2Slides}
              </div>
            </div>
          </Carousel.Item>
        );
    };

    const pressDiv = () => {
        const mediaArticles = [];
        for (let i=0; i < section2Group; i++) {
          mediaArticles.push(
            section2CarouselSlide(i)
          );
        };
        return mediaArticles;
    };

    const pressDivMobile = [];
    for (let i = 0; i <= 3; i++) {
      pressDivMobile.push(
        <Carousel.Item>
            <div className="container sm-w85">
              <div className="row">
                <div key={i} className="col-12">
                  <a href={section2Articles[i].pressArticleLink}>
                    <img
                      className="w-100"
                      src={section2Articles[i].pressFeaturedImage.file.url}
                      alt={section2Articles[i].pressArticleTitle}
                    />
                    <h2 className="font-bigcas mt30 red">
                      {section2Articles[i].pressArticleTitle}
                    </h2>
                    <p className="font-gtaM mt20 black">{section2Articles[i].pressArticleSource}</p>
                  </a>
                </div>
              </div>
            </div>
        
        </Carousel.Item>
        );
    };

    //SECTION 3 MAPPING
    // const section3Data = (index) => {
    //     return(
    //         <div className="col-lg-3 col-md-6 features-section-container">
    //             <h1 className="font-gtaM section-title ">{section3Articles[index].title}</h1>
    //                 <div className="section-logo">
    //                     <img alt="brands" height="140" src={section3Articles[index].image.file.url} />
    //                 </div>
    //                 <p className="section-desc">
    //                     {section3Articles[index].description}
    //                 </p>
    //                 <Link to="/venues/rooms/showroom">
    //                     <button className="btn">{section3Articles[index].buttonText}</button>
    //                 </Link>
    //         </div>
    //     )
    // }
    
    // const section3Enumerate = () => {
    //     for (let i = 0; i <= section3Articles.length; i++) {
    //         return (
    //             section3Data(i)
    //         )
    //     }
    // }
    
    return (
      <Layout>
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title="About Us" />

        <section id="link-tabs" className="pt-4 pb-4">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <ul className="nav justify-content-center">
                  <li className="font-gtaM">
                    <input
                      data-id="historySection"
                      id="btn-history"
                      className={`center-btns nav-link quick-link ${historySection}`}
                      onClick={this.scrollHandler}
                      value={tabName01}
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <input
                      data-id="pressSection"
                      id="btn-press"
                      className={`center-btns nav-link quick-link ${pressSection}`}
                      onClick={this.scrollHandler}
                      value={tabName02}
                      type="button"
                    />
                  </li>
                  <li className="font-gtaM">
                    <input
                      data-id="brandSection"
                      id="btn-brand"
                      className={`center-btns nav-link quick-link ${brandSection}`}
                      onClick={this.scrollHandler}
                      value={tabName03}
                      type="button"
                    />
                  </li>

                  <div className="sidenav">
                    <li className={historySection}>
                      <input
                        data-id="historySection"
                        className={`font-gtaM ${historySection} side-scrollers`}
                        id="history-01"
                        onClick={this.scrollHandler}
                        value={mobileTabName01}
                        type="button"
                      />
                    </li>
                    <li className={pressSection}>
                      <input
                        data-id="pressSection"
                        className={`font-gtaM ${pressSection} side-scrollers`}
                        id="press-02"
                        onClick={this.scrollHandler}
                        value={mobileTabName02}
                        type="button"
                      />
                    </li>
                    <li className={brandSection}>
                      <input
                        data-id="brandSection"
                        className={`font-gtaM ${brandSection} side-scrollers`}
                        id="brand-03"
                        onClick={this.scrollHandler}
                        value={mobileTabName03}
                        type="button"
                      />
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="historySection" className="pt-5 pb-5">
          <div className="container sm-w85">
            <div className="row">
              <div className="col-sm-6 mt60">
                <h3 className="brown">{section1TabName}</h3>
                <h1 className="red">{section1Title}</h1>
                <p className="font-bigcas">
                  {section1Subtitle}
                </p>
              </div>
            </div>
          </div>
          <div className="container sm-w85">
            <div className="row mt80">
              <div className="col-md-6">
                <img
                  className="w-100"
                  src={section1Image1.file.url}
                  alt=""
                />
                <p className="photo-caption mt20">
                    {section1Image1Caption}
                  <strong>
                    {section1ImageCredit}
                  </strong>
                </p>
              </div>
              <div className="col-md-6">
                <p className="white-space-p-l">
                    {section1Paragraph1.content[0].content[0].value}
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="sm-w85">
          <TestimonialSection testimonialSection={testimonialAbout} />
        </div>

        <section id="historySection">
          <div className="container sm-w85">
            <div className="row mt80">
              <div className="col-md-6">
                <p>
                  {section1Paragraph2.content[0].content[0].value}
                </p>
              </div>
              <div className="col-md-6">
                <img
                  className="w-100"
                  src={section1Image2.file.url}
                  alt=""
                />
                <p className="photo-caption mt20">
                  {section1Image2Caption}
                  <strong>
                    {section1Image2Credit}
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="pressSection" className="mt80 pt-5 pb120">
          <div className="container sm-w85">
            <div className="row">
              <div className="col-sm-7 mt60">
                <h3 className="brown">{section2TabName}</h3>
                <h1 className="red">{section2Title}</h1>
                <p className="bigcas mb50">
                    {section2SubTitle}
                </p>
              </div>
            </div>
          </div>
          <div>
            {/* DESKTOP CAROUSEL */}
            <Carousel className="d-none d-sm-none d-md-block">
              {pressDiv()}
            </Carousel>

            {/* MOBILE CAROUSEL */}
            <Carousel className="d-block d-sm-block d-md-none">
              {pressDivMobile}
            </Carousel>
          </div>
        </section>

        <section id="brandSection" className="section-section pt-5 pb-5">
          <div className="container sm-w85">
            <div className="row">
              <div id="brandTitle" className="col-sm-7 mt60">
                <h3 className="red">{section3TabName}</h3>
                <h1 className="red">{section3Title}</h1>
              </div>
            </div>
          </div>
          <div id="brandFeatures" className="container sm-w85">
            <div className="row justify-content-center">
              {section3Articles.map((article, i) => (
                <div className="col-lg-3 col-md-6 features-section-container">
                    <h1 className="font-gtaM section-title ">{article.title}</h1>
                    <div className="section-logo">
                        <img alt="brands" height="140" src={article.image.file.url} />
                    </div>
                    <p className="section-desc">
                        {article.description}
                    </p>
                    <Link to={article.link}>
                        <button className="btn">{article.buttonText}</button>
                    </Link>
                </div>
              ))}
            </div>
          </div>
        </section>

        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default About;

export const pageQuery = graphql`
  query AboutIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulAboutAboutPage {
        nodes {
          mobileTabName01
          mobileTabName02
          mobileTabName03
          tabName01
          tabName02
          tabName03
          section1Image1 {
            file {
              url
            }
          }
          section1Image1Caption
          section1Image2 {
            file {
              url
            }
          }
          section1Image2Caption
          section1Image2Credit
          section1ImageCredit
          section1Subtitle
          section1TabName
          section1Title
          section2Articles {
            pressArticleLink
            pressArticleSource
            pressArticleTitle
            pressFeaturedImage {
              file {
                url
              }
            }
          }
          section2SubTitle
          section2TabName
          section2Title
          section3TabName
          section3Title
          section3Articles {
            title
            buttonText
            link
            description
            image {
              file {
                url
              }
            }
          }
          section1Paragraph1 {
            content {
              content {
                value
              }
            }
          }
          section1Paragraph2 {
            content {
              content {
                value
              }
            }
          }
        }
    }
    allContentfulAboutPagePressSection(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          pressArticleLink
          pressFeaturedImage {
            file {
              url
            }
          }
          pressArticleTitle
          pressArticleSource
        }
      }
    }
    allContentfulTestimonialSection {
      edges {
        node {
          testimonyBy
          testimony {
            testimony
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
